<template>
  <div class="aboutUs">
    <TopNavBar />
    <Carousel :carousels="carousels">
      <el-button slot="button" class="btn" @click="jumpClick"
        >去联系 <i class="el-icon-right"></i
      ></el-button>
    </Carousel>
    <div class="box">
      <div class="business_box">
        <Title title="主营业务" />
        <div class="business">
          <div v-for="(item, index) in business" :key="index" class="item">
            <h3>{{ item.title }}</h3>
            <p>{{ item.content }}</p>
          </div>
          <img src="@/assets/images/aboutUs/business_img.png" alt="" />
        </div>
      </div>
    </div>
    <div class="mission_box">
      <Title title="公司使命" />
      <div class="missions">
        <div class="bg_img">
          <div v-for="(item, index) in missions" :key="index" class="item">
            <h3>{{ item.title }}</h3>
            <p>— {{ item.title2 }} —</p>
          </div>
        </div>
      </div>
      <div class="bottom_box">
        <div v-for="(item, index) in missions" :key="index">
          {{ item.title3 }}
        </div>
      </div>
    </div>
    <div class="box" v-show="courses.length">
      <div class="course_box">
        <Title title="发展历程" />
        <div class="courses">
          <div
            v-for="(item, index) in courses"
            :key="index"
            class="item"
            @click="onYear(item, index)"
            :class="courseActive === index ? 'courseActive' : ''"
          >
            <p>{{ item.year }}</p>
            <img
              v-if="courseActive === index"
              src="@/assets/images/aboutUs/course4.svg"
              alt=""
            />
            <img v-else src="@/assets/images/aboutUs/course3.svg" alt="" />
            <div>
              <div></div>
            </div>
          </div>
          <div class="bottom">
            <img src="@/assets/images/aboutUs/course2.svg" alt="" />
          </div>
        </div>
        <div class="information_box">
          <div v-for="(item, index) in courseInfos" :key="index">
            <div class="item">
              <div class="title left">
                <div v-show="index % 2 === 0">
                  <span>{{ item.title }}</span>
                  <div></div>
                </div>
              </div>
              <div class="date">{{ item.date }}</div>
              <div class="title right">
                <div v-show="index % 2 === 1">
                  <div></div>
                  <span>{{ item.title }}</span>
                </div>
              </div>
            </div>
            <div v-if="index < courseInfos.length - 1" class="bottom"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="contact_box" id="anchor">
      <Title title="联系我们" />
      <div class="contact">
        <div class="address" id="container"></div>
        <div class="information">
          <div class="item">
            <img src="@/assets/images/aboutUs/icon1.png" alt="" />
            <h3>公司地址</h3>
            <p>浙江省台州市椒江区第一时间生活广场2楼255室</p>
          </div>
          <div class="item">
            <img src="@/assets/images/aboutUs/icon2.png" alt="" />
            <h3>客服热线</h3>
            <p>0576-81810718（周一至周五 9:00-15:00）</p>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { aboutUsList } from '../api/index'
import AMapLoader from "@amap/amap-jsapi-loader";
export default {
  name: "AboutUs",
  data() {
    return {
      business: [
        {
          title: "智慧文博场馆",
          content:
            "基于数字孪生中台+场景+应用产品矩阵化架构，构建智慧科馆、智慧博物馆数字化运维平台，为科技馆、博物馆智慧运维提供数字化技术支撑。",
        },
        {
          title: "AR/VR数字展厅",
          content:
            "采用最新多媒体互动技术，投影融合快速搭建沉浸式数字化多媒体展厅。",
        },
        {
          title: "智慧政务",
          content:
            "基于智慧城市基础架构平台，结合政务系统及各政务部门数字化改革要求，定制开发各类业务和应用平台及数字化应用。",
        },
        {
          title: "智慧医疗",
          content:
            "利用领先的信息技术和软件产品，帮助医疗机构实现智慧化管理，助力医疗机构全方位数智化转型，实现高质量可持续发展。",
        },
        {
          title: "数智集团",
          content:
            "运用智能化技术和算法融合，为企业提供工具+平台+场景+机制的体系化流程优化解决方案，助力企业流程管理更高效。",
        },
      ],
      missions: [
        {
          title: "INFORMATIZATION",
          title2: "信息化",
          title3: "信息化技术起航",
        },
        {
          title: "DIGITALIZATION",
          title2: "数字化",
          title3: "数字化经济逐浪",
        },
        {
          title: "INTELLIGENCE",
          title2: "智能化",
          title3: "智能化时代扬帆",
        },
      ],
      courses: null,
      courseActive: 0,
      courseInfos: null,
      map: null,
      carousels: [
        {
          imgUrl: require("@/assets/images/carousels/8.png"),
        },
      ],
    };
  },
  created() {
    this.loadAboutUsList()
  },
  mounted() {
    this.initAMap(121.415772, 28.653729);
  },
  methods: {
    initAMap(lng, lat) {
      var that = this;
      AMapLoader.load({
        key: "9d5d867e7fe51867e37324dd95002bb2", //设置您的key
        version: "2.0",
        plugins: [
          "AMap.ToolBar",
          "AMap.Driving",
          "AMap.Marker",
          "AMap.PlaceSearch ",
          "AMap.AutoComplete",
          "AMap.Geolocation",
          "AMap.InfoWindow",
        ],
        AMapUI: {
          version: "1.1",
          plugins: [],
        },
        Loca: {
          version: "2.0",
        },
      })
        .then((AMap) => {
          that.map = null;
          if (!this.map) {
            that.map = new AMap.Map("container", {
              viewMode: "3D",
              zoom: 18, //初始化地图级别
              // zooms: [2, 22],
              center: [lng, lat], //初始化地图中心点位置
              resizeEnable: true,
            });
            that.marker = new AMap.Marker({
              content:
                '<img style="height:60px; width: 60px;" src="' +
                require("@/assets/images/aboutUs/location_icon.png") +
                '" />',
              position: new AMap.LngLat(lng, lat),
              offset: new AMap.Pixel(-30, -30),
            });
            that.marker.setMap(that.map);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    jumpClick() {
      document.querySelector("#anchor").scrollIntoView(true);
    },
    async loadAboutUsList() {
      const res = await aboutUsList()
      console.log(res, 'res');
      this.courses = res
      this.courseInfos = res[0].children.map(res => {
        return {
          date: res.months,
          title: res.event
        }
      })
    },
    onYear(item, index){
      this.courseInfos = item.children.map(res => {
        return {
          date: res.months,
          title: res.event
        }
      })
      this.courseActive = index
    }
  },
};
</script>

<style scoped lang="scss">
.aboutUs {
  ::v-deep .btn {
    position: absolute;
    left: 15%;
    top: 70%;
    transform: translate(-50%, -50%);
    z-index: 100;
    width: 8vw;
    height: 4vw;
    padding: 0;
    background-color: #00d577;
    font-size: 25px;
    color: #fff;
  }
  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .business_box {
    margin-top: 85px;
    width: 1330px;
    .business {
      position: relative;
      margin-top: 70px;
      height: 655px;
      img {
        position: absolute;
        width: 290px;
        height: 276px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .item {
        position: absolute;
        width: 460px;
        padding: 24px 20px;
        background-color: #ffffff;
        box-shadow: 0px 0px 5px #86baea40;
        z-index: 100;
        h3 {
          font-weight: 400;
          font-size: 25px;
          color: #2e8eff;
          text-align: left;
          line-height: 33px;
        }
        p {
          margin-top: 12px;
          font-weight: 200;
          font-size: 20px;
          text-align: left;
        }
      }
      .item:nth-child(1) {
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }
      .item:nth-child(2) {
        top: 50%;
        right: 0;
        transform: translateY(-60%);
      }
      .item:nth-child(3) {
        bottom: 0;
        right: 70px;
      }
      .item:nth-child(4) {
        bottom: 0;
        left: 70px;
      }
      .item:nth-child(5) {
        top: 50%;
        left: 0;
        transform: translateY(-60%);
      }
    }
  }
  .mission_box {
    margin-top: 70px;
    padding-top: 66px;
    height: 710px;
    background-color: #f6fafd;
    .missions {
      margin-top: 70px;
      display: flex;
      justify-content: center;
      .bg_img {
        width: 1040px;
        height: 446px;
        background-image: url(~@/assets/images/aboutUs/mission.png);
        background-size: 1200px 446px;
        background-repeat: no-repeat;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 75px 0 85px;
        .item {
          margin-top: 108px;
          width: 260px;
          h3 {
            font-weight: 650;
            font-size: 16px;
            letter-spacing: 0.5px;
            color: #2e8eff;
            text-align: center;
            line-height: 26px;
          }
          p {
            font-weight: 200;
            letter-spacing: 0.5px;
            color: #2e8eff;
            text-align: center;
            line-height: 26px;
            font-size: 14px;
          }
        }
      }
    }
    .bottom_box {
      margin: 20px auto 0;
      display: flex;
      justify-content: space-between;
      width: 1200px;
      > div {
        flex: 1;
        font-weight: 200;
        font-size: 25px;
        letter-spacing: 2px;
        text-align: center;
        line-height: 26px;
        color: #333;
      }
    }
  }
  .course_box {
    margin-top: 100px;
    width: 1219px;
    .courses {
      position: relative;
      margin-top: 65px;
      height: 130px;
      display: flex;
      justify-content: space-around;
      .item {
        position: relative;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        cursor: pointer;
        margin-bottom: 1px;
        p {
          position: absolute;
          font-weight: 400;
          font-size: 22px;
          color: #ffffff;
          bottom: 50px;
        }
        > div {
          min-width: 20px;
          min-height: 20px;
          border-radius: 50%;
          background-color: #dddddd50;
          display: flex;
          justify-content: center;
          align-items: center;
          > div {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #dddddd;
          }
        }
      }
      .courseActive {
        > img {
          width: 100px;
          height: 111px;
        }
        p {
          font-size: 30px;
          bottom: 65px;
        }
        > div {
          background-color: #006eff50;
          > div {
            background-color: #006eff;
          }
        }
      }
      .bottom {
        position: absolute;
        bottom: 0;
        width: 1219px;
        height: 20px;
        background-image: url(~@/assets/images/aboutUs/course1.svg);
        background-repeat: no-repeat;
        background-position: center;
        z-index: -1;
        img {
          position: absolute;
          right: -6px;
          top: 6px;
          transform: rotate(90deg);
        }
      }
    }
    .information_box {
      margin-top: 70px;
      display: flex;
      flex-direction: column;
      display: flex;
      flex-direction: column;
      > div {
        .item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .date {
            min-width: 120px;
            height: 40px;
            line-height: 40px;
            border: 1px solid #1f74ff;
            border-radius: 20px;
            font-weight: 200;
            font-size: 20px;
            color: #1f74ff;
            font-family: "AlibabaPuHuiTi-Light";
          }
          .title {
            width: 100%;
            > div {
              font-weight: 200;
              font-size: 20px;
              line-height: 30px;
              display: flex;
              align-items: center;
              > div {
                width: 60px;
                height: 1px;
                background-color: rgb(31, 116, 255);
                margin: 0 10px;
              }
            }
          }
          .left {
            > div {
              justify-content: flex-end;
            }
          }
          .right {
            > div {
              justify-content: flex-start;
            }
          }
        }
        .bottom {
          width: 1px;
          height: 30px;
          background-color: rgb(31, 116, 255);
          margin: 12px auto;
        }
      }
    }
  }
  .contact_box {
    padding: 0 170px;
    margin: 110px 0 80px;
    .contact {
      margin-top: 70px;
      display: flex;
      justify-content: space-between;
      ::v-deep .address {
        width: 1000px;
        height: 544px;
        .amap-logo,
        .amap-copyright {
          display: none !important;
        }
      }
      .information {
        padding: 55px 0 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .item {
          position: relative;
          padding: 40px 0 50px;
          width: 520px;
          height: 90px;
          box-shadow: 0px 0px 5px #86baea40;
          img {
            position: absolute;
            width: 50px;
            height: 50px;
            top: -25px;
            left: 50%;
            transform: translateX(-50%);
          }
          h3 {
            font-weight: 400;
            font-size: 25px;
            color: #000000;
            line-height: 40px;
          }
          p {
            font-weight: 200;
            font-size: 20px;
            color: #000000;
            line-height: 40px;
          }
        }
      }
    }
  }
}
@media (max-width: 1440px) {
  .aboutUs {
    ::v-deep .btn {
      font-size: 14px;
    }
  }
}
</style>
